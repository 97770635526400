import { useState } from "react"
import { useOktaAuth } from '@okta/okta-react';
import { Button, Tooltip, CircularProgress } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles"
import {McKSansLight} from "../styles/fonts"


const useStyles = makeStyles({
    button: {
        top: '2.3%',
        right: '5%',
        position: 'absolute',
        width: '80px',
        color: 'white',
        fontFamliy: McKSansLight,
        fontWeight: 'lighter',
        borderRadius: '5px',
        background: 'transparent',
    }
})



interface State {
    message: string;
    isOpen: boolean;
    isError: boolean;
    isLoading: boolean;
}





function LogoutButton() {
    const classes = useStyles();
    const { oktaAuth, authState } = useOktaAuth();
    
    const [values, setValues] = useState<State>({
        message: '',
        isOpen: false,
        isError: false,
        isLoading: false,
    })

   
    const handleLogout = async () => {

        setValues({ ...values, isLoading: true })
       
        await oktaAuth.signOut();

    };



    const renderButton = () => {


        if (values.isLoading) {
            return <CircularProgress />
        }

        if (authState?.isAuthenticated) {
            return (
                <Tooltip title="End session">
                    <Button
                        className={classes.button}
                        onClick={handleLogout}
                    >
                        Logout
                    </Button>


                </Tooltip>
            );
        }
    };

    return (
        <div>
            {renderButton()}
        </div>
    );

};

export default LogoutButton;